// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App-header {
  min-height: 3.8rem;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background: #000;
}
.App-header .header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  max-width: 1400px;
  margin: 0 auto;
  width: 100%;
}
.App-header .site-name {
  color: white;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.2rem;
  letter-spacing: 0.1em;
}
.App-header .site-name .emphasis {
  font-weight: 100;
}
.App-header nav {
  display: flex;
  gap: 2rem;
  align-items: center;
}
.App-header nav .nav-link {
  color: white;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  font-size: 1rem;
  font-weight: 500;
}
.App-header nav .nav-link:hover {
  text-decoration: underline;
}
.App-header nav .nav-link:active {
  color: #666;
}

main, .main-content {
  margin-top: 80px;
}`, "",{"version":3,"sources":["webpack://./src/styles/components/_header.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,YAAA;EACA,gBAAA;AACF;AACE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,kBAAA;EACA,iBAAA;EACA,cAAA;EACA,WAAA;AACJ;AAEE;EACE,YAAA;EACA,qBAAA;EACA,iBAAA;EACA,iBAAA;EACA,qBAAA;AAAJ;AAEI;EACE,gBAAA;AAAN;AAIE;EACE,aAAA;EACA,SAAA;EACA,mBAAA;AAFJ;AAII;EACE,YAAA;EACA,qBAAA;EACA,gCAAA;EACA,eAAA;EACA,gBAAA;AAFN;AAIM;EACE,0BAAA;AAFR;AAKM;EACE,WAAA;AAHR;;AASA;EACE,gBAAA;AANF","sourcesContent":[".App-header {\n  min-height: 3.8rem;\n  width: 100%;\n  position: fixed;\n  top: 0;\n  left: 0;\n  z-index: 100;\n  background: #000;\n  \n  .header-content {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 1rem 2rem;\n    max-width: 1400px;\n    margin: 0 auto;\n    width: 100%;\n  }\n\n  .site-name {\n    color: white;\n    text-decoration: none;\n    font-weight: bold;\n    font-size: 1.2rem;\n    letter-spacing: 0.1em;\n    \n    .emphasis {\n      font-weight: 100;\n    }\n  }\n  \n  nav {\n    display: flex;\n    gap: 2rem;\n    align-items: center;\n    \n    .nav-link {\n      color: white;\n      text-decoration: none;\n      transition: all 0.2s ease-in-out;\n      font-size: 1rem;\n      font-weight: 500;\n      \n      &:hover {\n        text-decoration: underline;\n      }\n      \n      &:active {\n        color: #666;\n      }\n    }\n  }\n}\n\nmain, .main-content {\n  margin-top: 80px;\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
