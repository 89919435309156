// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lightbox-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.95);
  z-index: 1000;
  animation: fadeIn 0.3s ease-in-out;
}

.lightbox-title {
  color: white;
  font-size: 1.5rem;
  margin: 0;
  font-weight: 500;
}

.lightbox-close {
  color: white;
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  padding: 0;
  line-height: 1;
}
.lightbox-close:hover {
  opacity: 0.7;
}

.lightbox-content {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 0;
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/components/_lightbox.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,qCAAA;EACA,aAAA;EACA,kCAAA;AACF;;AAEA;EACE,YAAA;EACA,iBAAA;EACA,SAAA;EACA,gBAAA;AACF;;AAEA;EACE,YAAA;EACA,gBAAA;EACA,YAAA;EACA,eAAA;EACA,eAAA;EACA,UAAA;EACA,cAAA;AACF;AACE;EACE,YAAA;AACJ;;AAGA;EACE,WAAA;EACA,YAAA;EACA,gBAAA;EACA,UAAA;EACA,kCAAA;AAAF;;AAGA;EACE;IAAO,UAAA;EACP;EAAA;IAAK,UAAA;EAGL;AACF","sourcesContent":[".lightbox-overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: rgba(0, 0, 0, 0.95);\n  z-index: 1000;\n  animation: fadeIn 0.3s ease-in-out;\n}\n\n.lightbox-title {\n  color: white;\n  font-size: 1.5rem;\n  margin: 0;\n  font-weight: 500;\n}\n\n.lightbox-close {\n  color: white;\n  background: none;\n  border: none;\n  font-size: 2rem;\n  cursor: pointer;\n  padding: 0;\n  line-height: 1;\n  \n  &:hover {\n    opacity: 0.7;\n  }\n}\n\n.lightbox-content {\n  width: 100%;\n  height: 100%;\n  overflow-y: auto;\n  padding: 0;\n  animation: fadeIn 0.3s ease-in-out;\n}\n\n@keyframes fadeIn {\n  from { opacity: 0; }\n  to { opacity: 1; }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
