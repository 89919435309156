// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
}

.grid-item-enter {
  opacity: 0;
  transform: scale(0.9);
}

.grid-item-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms ease-in, transform 300ms ease-in;
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: #121212;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/components/PortfolioGrid.scss"],"names":[],"mappings":"AACA;EACI,SAAA;EACA,UAAA;EACA,sBAAA;AAAJ;;AAGE;EACE,kBAAA;AAAJ;;AAIE;EACE,UAAA;EACA,qBAAA;AADJ;;AAIE;EACE,UAAA;EACA,mBAAA;EACA,0DAAA;AADJ;;AAKE;EACE;IACE,yBAAA;EAFJ;AACF","sourcesContent":["// Reset and base styles\n* {\n    margin: 0;\n    padding: 0;\n    box-sizing: border-box;\n  }\n  \n  body {\n    overflow-x: hidden;\n  }\n  \n  // Grid item animations and transitions\n  .grid-item-enter {\n    opacity: 0;\n    transform: scale(0.9);\n  }\n  \n  .grid-item-enter-active {\n    opacity: 1;\n    transform: scale(1);\n    transition: opacity 300ms ease-in, transform 300ms ease-in;\n  }\n  \n  // Optional dark mode styles\n  @media (prefers-color-scheme: dark) {\n    body {\n      background-color: #121212;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
